import moment from 'moment'
import { apolloClient } from '~/common/apollo'
import { action, computed, observable, store } from '~/common/mobx.decorator'
import { notifyStore, routerStore } from '~/stores'
import {
	DELETE_ALL_READ_NOTIFICATION_MUTATION,
	DELETE_NOTIFICATION_MUTATION,
	GET_NOTIFICATIONS,
	MARK_ALL_NOTIFICATION_STATUS_MUTATION,
	MARK_NOTIFICATION_STATUS_MUTATION,
} from './care-notification.graphql'
import { NOTIFICATION_EVENT, NOTIFICATION_STATUS } from '~/common/constants/notification'
import { PATHS } from '~/common/constants'
import { commonSigninStore } from '~/features/common-signin/common-signin.store'

@store()
class CareNotificationStore {
	@observable pageInfo
	@observable totalUnread = 0
	@observable notifications = []

	@computed
	get newNotifications() {
		return this.notifications.filter((item) => moment(item.createdAt).isSame(moment(), 'day'))
	}

	@computed
	get oldNotifications() {
		return this.notifications.filter((item) => !moment(item.createdAt).isSame(moment(), 'day'))
	}

	@action
	refreshNotifications = async () => {
		this.pageInfo = {
			hasNextPage: true,
			endCursor: null,
		}

		this.fetchNotifications()
	}

	@action
	fetchNotifications = async () => {
		if (this.loading || !this.pageInfo.hasNextPage) {
			return
		}

		try {
			this.loading = true
			const variables = { first: 20, after: this.pageInfo.endCursor }
			const response = await apolloClient.query({ query: GET_NOTIFICATIONS, variables })
			this.notifications = [...this.notifications, ...response.data.notifications.nodes]
			this.pageInfo = response.data.notifications.pageInfo
			this.totalUnread = response.data.notifications.totalUnread
		} catch (error) {
			console.error(error)
		} finally {
			this.loading = false
		}
	}

	@action
	refreshNotificationAfterAction = async () => {
		this.pageInfo = {
			hasNextPage: true,
			endCursor: null,
		}

		try {
			this.loading = true
			const variables = { first: this.notifications.length || 20, after: this.pageInfo.endCursor }
			const response = await apolloClient.query({ query: GET_NOTIFICATIONS, variables })
			this.notifications = [...response.data.notifications.nodes]
			this.pageInfo = response.data.notifications.pageInfo
			this.totalUnread = response.data.notifications.totalUnread
		} catch (error) {
			console.error(error)
		} finally {
			this.loading = false
		}
	}

	@action
	markAllAsRead = async () => {
		try {
			await apolloClient.mutate({ mutation: MARK_ALL_NOTIFICATION_STATUS_MUTATION })
			await this.refreshNotificationAfterAction()
			await notifyStore.success('All notifications have been marked as read')
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	deleteAllRead = async () => {
		try {
			await apolloClient.mutate({ mutation: DELETE_ALL_READ_NOTIFICATION_MUTATION })
			notifyStore.success('All read notifications have been deleted')
			this.refreshNotificationAfterAction()
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	markNotificationAsRead = async (notification) => {
		try {
			await apolloClient.mutate({
				mutation: MARK_NOTIFICATION_STATUS_MUTATION,
				variables: { notificationIds: [notification.id], status: NOTIFICATION_STATUS.read },
			})
			notification.status = NOTIFICATION_STATUS.read
			this.notifications = this.notifications.map((item) => (item.id === notification.id ? notification : item))
			this.totalUnread = this.totalUnread - 1
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	handleClickNotification = async (notification) => {
		if (notification.status !== NOTIFICATION_STATUS.read) {
			await this.markNotificationAsRead(notification)
		}

		if (notification.event === NOTIFICATION_EVENT.job_alert) {
			await routerStore.goPage(`${PATHS.care.search}?saveSearchId=${notification?.objectId}`)
		}

		if (notification.event === NOTIFICATION_EVENT.job_rating) {
			await routerStore.goPage(`${PATHS.care.jobs}/${notification?.additionalData?.job_id}?isShowRatingForm=true`)
		}
		if (NOTIFICATION_EVENT.credential_events.includes(notification.event)) {
			await routerStore.goPage(PATHS.care.credentials)
		}
		if (notification?.event === NOTIFICATION_EVENT.timecard_submission) {
			await routerStore.goPage(PATHS.care.timecards)
		}
		if (notification?.event === NOTIFICATION_EVENT.new_feed) {
			await routerStore.goPage(`${PATHS.care.news_feed}`)
		}
		if (notification.objectType === NOTIFICATION_EVENT.Job) {
			await routerStore.goPage(`${PATHS.care.jobs}/${notification?.objectId}`)
		}

		if (notification.event === NOTIFICATION_EVENT.job_matches) {
			await routerStore.goPage(`${PATHS.care.suggested_jobs}`)
		}
		if (notification.event === NOTIFICATION_EVENT.assignment_check_out) {
			if (!!notification?.additionalData?.job_id) {
				await routerStore.goPage(`${PATHS.care.jobs}/${notification?.additionalData?.job_id}`)
			}
		}
		if (notification.event === NOTIFICATION_EVENT.new_message) {
			await routerStore.goPage(`${PATHS.care.messages}/${notification?.additionalData?.conversationId}`)
		}

		if (notification.event === NOTIFICATION_EVENT.job_search) {
			await routerStore.goPage(PATHS.care.search)
		}

		if (notification.event === NOTIFICATION_EVENT.campaign && commonSigninStore.showCampaign === false) {
			await commonSigninStore.fetchCampaignDetail(notification?.objectId)
			await commonSigninStore.setHideCheckBoxConfirm(true)
			await commonSigninStore.setShowCampaign(true)
		}
	}

	@action
	handleDeleteNotification = async (notification) => {
		try {
			const variables = { notificationIds: [notification.id] }
			await apolloClient.mutate({ mutation: DELETE_NOTIFICATION_MUTATION, variables })
			notifyStore.success('Your notification has been deleted')
			this.refreshNotificationAfterAction()
		} catch (error) {
			notifyStore.error(error.message)
		}
	}
	@action
	setNotifications = (notifications) => {
		this.notifications = notifications
	}
}

export const careNotificationStore = new CareNotificationStore()
